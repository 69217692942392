/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd,
dt {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

:root {
  --page-background: #F7F7FF;
  --text-color: #222233;
  --progress: #9999AA;
  --green: #00EB7C;
  --red: #FF0000;
  --primary: #1B73E8;
}

html {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  line-height: 1.3;
  font-weight: 300;
  color: #222233;
}

body {
  background: var(--page-background);
}

h2 {
  font-size: 1.6rem;
  font-weight: 800;
}

h3 {
  font-size: 1.6rem;
  font-weight: 300;
}

.page, .player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.page {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.page-contents {
  text-align: center;
}

.message {
  margin-top: 0.5rem;
  font-size: 0.8rem;
  font-weight: 800;
}
.message.is-error {
  color: var(--red);
}

input[type=text] {
  text-align: center;
  font-size: 1.5rem;
  border: 2px solid #808080;
  border-radius: 5px;
}
input[type=text].is-valid {
  color: var(--green);
}
input[type=text].is-error {
  color: var(--red);
}

button,
a.button {
  display: inline-block;
  -webkit-appearance: none;
  text-decoration: none;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  background-color: var(--text-color);
  color: white;
  font-size: 0.7rem;
  font-weight: bold;
}
button.is-success,
a.button.is-success {
  background: var(--green);
}
button.is-primary,
a.button.is-primary {
  background: var(--primary);
}
button[disabled],
a.button[disabled] {
  background: #d0d0d0;
}
button.is-small,
a.button.is-small {
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 0.7rem;
}

progress {
  border: 2px solid var(--progress);
  height: 20px;
  border-radius: 10px;
  background: none;
}

progress::-webkit-progress-bar {
  background: none;
}

progress::-webkit-progress-value {
  background: var(--progress);
  border-radius: 10px;
  border: 2px solid var(--page-background);
}

aside {
  margin: 0 auto;
  background: #F0F0F8;
  width: 550px;
  border-radius: 20px;
  padding: 30px 30px;
}
aside p {
  margin-bottom: 1em;
}

.notifications {
  width: 250px;
}
.notifications button {
  margin-top: 20px;
  width: 100%;
  transition: all 0.2s ease-in-out;
}
.notifications button.is-out {
  opacity: 0;
  pointer-events: none;
  transform: scale(0.3);
}
.notifications--spacer {
  height: 0;
  transition: height 0.5s ease-in-out;
}

@keyframes notification-in {
  from {
    transform: scale(0.1);
  }
  to {
    transform: scale(1);
  }
}
.notification {
  width: 250px;
  font-size: 12px;
  padding: 20px;
  border-radius: 20px;
  margin-top: 20px;
  font-weight: bold;
  color: white;
  text-align: center;
  background: linear-gradient(180deg, #9A9AA6, #222233);
  animation-name: notification-in;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.notification.is-catch {
  background: #00CBE3;
}

.player .notifications {
  position: absolute;
  bottom: 50px;
  right: 50px;
  z-index: 4;
}

.is-measuring {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  visibility: hidden !important;
}

video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

video.is-active {
  z-index: 3;
}

.visor {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
}

.windows-warning {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10000;
  text-align: center;
  background: red;
  color: white;
  font-weight: bold;
  font-size: 0.8rem;
  padding: 10px;
  box-sizing: border-box;
}
.windows-warning button {
  margin-left: 1em;
  background: #7a0000;
}

body.not-windows .windows-only {
  display: none !important;
}
/*# sourceMappingURL=index.01ad426d.css.map */
